<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/html-self-closing -->
  <div>
    <Overplay v-if="isShow" />
    <PopupLanguage
      :data="languageList"
      @handlerChooseLanguage="handlerChooseLanguage"
    />
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="9">
            <Fieldset id="languagessetting" :title="$t('Languages Setting')">
              <div v-if="languageOptions.length > 0">
                <div v-for="(item, index) in languageOptions" :key="index">
                  <div
                    v-if="
                      languageOptions[index].locale !== undefined &&
                        languageOptions[index].locale !== null
                    "
                    class="d-flex align-items-center"
                  >
                    <div
                      class="d-flex align-items-center"
                      style="width: 25%; border-radius: 4px; padding: 6px 18px; border: 1px solid #b4b7bd;"
                      :style="
                        item.is_active === false ? 'background: #f6f6f6' : ''
                      "
                    >
                      <img
                        :src="item.img"
                        :alt="item.locale"
                        style="width: 25px; height: 25px; object-fit: contain; display: block"
                        class="mr-2"
                      />{{ item.name }}
                    </div>
                    <b-form-group
                      style="width: 40%;"
                      :label="$t('on/off')+ `:`"
                      label-cols-md="12"
                      class="ml-2 mb-0"
                    >
                      <b-form-checkbox
                        v-model="item.is_active"
                        checked="true"
                        class="custom-control-success"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                    <!-- Remove Button -->
                    <!-- <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="ml-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button> -->
                  </div>
                  <div v-else>
                    <b-link v-b-modal.modal-xl>
                      {{ $t('Choose language') }}
                    </b-link>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="text-right">
                <b-button
                  v-if="languageOptions.length < limitLanguage"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeateAgain"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>
                    {{ $t('Add New') }} ({{ languageOptions.length }}/{{ limitLanguage }})
                  </span>
                </b-button>
              </div>
            </Fieldset>
          </b-col>
          <b-col md="3">
            <div class="position-sticky top">
              <Fieldset id="status" :title="$t('Status')">
                <hr />
                <!-- Submit -->
                <div class="text-right">
                  <b-button
                    variant="info"
                    type="submit"
                    class="text-right"
                    @click.prevent="validationForm"
                  >
                    {{ $t('Update') }}
                  </b-button>
                </div>
              </Fieldset>
              <Fieldset id="languagedefault" :title="$t('Language Default')">
                <div class="d-flex align-items-center w-100">
                  <img
                    :src="languageDefault.img"
                    :alt="languageDefault.locale"
                    style="width: 20px; height: 20px; object-fit: contain;"
                    class="mr-2"
                  />
                  <div class="w-100">
                    <validation-provider
                      #default="{ errors }"
                      name="Is active"
                      rules="required"
                    >
                      <v-select
                        v-if="languageOptionsDefault"
                        v-model="languageDefault"
                        label="name"
                        :options="languageOptionsDefault"
                        :state="errors.length > 0 ? false : null"
                      >
                        <template #option="{ locale, name, img }">
                          <img
                            :src="img"
                            :alt="locale"
                            style="width: 15px; height: 15px; object-fit: contain;"
                            class="mr-2"
                          />
                          <span> {{ name }}</span>
                        </template>
                      </v-select>
                      <small class="text-danger mt-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
                <br />
                <small class="text-muted">
                  {{ $t('Select the original language your website was written in') }}
                </small>
              </Fieldset>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unneeded-ternary */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BForm,
  BFormCheckbox,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import languageJson from '@/libs/lang/lang'
import PopupLanguage from './PopupLanguage.vue'

export default {
  /* eslint-disable implicit-arrow-linebreak */
  /* eslint-disable operator-linebreak */
  /* eslint-disable function-paren-newline */
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BForm,
    BFormCheckbox,
    BLink,
    ValidationProvider,
    ValidationObserver,
    PopupLanguage,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      listLanguageLength: 0,
      required,
      limitLanguage: 2,
      isShow: false,
      languageDefault: {
        name: null,
        locale: null,
        img: null,
        is_active: true,
      },
      languageJson: {
        language: [],
      },
      languageList: [],
      // languageList: languageJson.language,
      languageOptions: [],
      languageOptionsDefault: [],
      languageAddList: [],
    }
  },
  async mounted() {
    this.languageJson.language = await this.loadLanguageList()
    this.languageList = await this.loadLanguageList()
    // this.setLanguage()
    let currLang = null
    currLang = this.languageJson.language.filter(
      val => val.locale === this.$i18n.locale,
    )
    currLang[0].is_active = true
    this.languageDefault = currLang[0]
    this.languageOptions = await this.loadLanguage()
  },
  methods: {
    async loadLanguageList() {
      const list = []
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_CLIENT_API_URL}/platform_language`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.items.map(val => {
              const item = {
                locale: val.iso_code,
                img: val.avatar,
                name: val.name,
              }
              list.push(item)
              return item
            })
            return list
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return list
    },
    handlerChooseLanguage(data) {
      this.languageOptions.splice(this.languageOptions.length - 1, 1)
      this.languageOptions.push(data)
      this.languageAddList.push(data)
    },
    repeateAgain() {
      this.languageOptions.push({
        locale: null,
        name: null,
        img: null,
        is_active: true,
      })
    },
    removeItem(index) {
      this.model.subLanguage.splice(index, 1)
      if (this.model.subLanguage.length <= 0) {
        this.model.subLanguage.push({
          locale: null,
          name: null,
          img: null,
          is_active: true,
        })
      }
    },
    async setLanguage() {
      // this.languageOptions
      let currLang = null
      const listLang = await this.loadLanguage()
      if (
        localStorage.getItem('siteID') !== undefined &&
        localStorage.getItem('siteID') !== null
      ) {
        currLang = this.languageJson.language.filter(
          val => val.locale === this.$i18n.locale,
        )
        currLang[0].is_active = true
        this.languageDefault = currLang[0]

        const findDefaultLang = listLang.find(
          x => x.locale === currLang[0].locale,
        )
        if (findDefaultLang === undefined) {
          const resLang = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/language`,
            {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              name: currLang[0].name,
              locale: currLang[0].locale,
              is_active: Number(currLang[0].is_active === true ? 1 : 0),
              img: 'null',
            },
          )
          if (resLang.status === 200) {
            if (resLang.data.status) {
              this.languageOptions = []
              this.languageOptions = await this.loadLanguage()
            }
          }
        } else {
          this.languageOptions = []
          this.languageOptions = listLang
        }
      }
    },
    async loadLanguage() {
      this.isShow = true
      const languageOptions = []
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/languages?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            this.languageOptionsDefault = []
            this.listLanguageLength = res.data.data.items.length
            res.data.data.items.map(val => {
              const langFil = this.languageJson.language.filter(
                lang => lang.locale === val.locale,
              )
              langFil[0].is_active = Boolean(val.is_active === 1 ? true : false)
              if (val.is_active !== 0) {
                this.languageOptionsDefault.push(langFil[0])
              }
              langFil[0].id = val.id
              return languageOptions.push(langFil[0])
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return languageOptions
    },
    async updateSite(params) {
      await Request.put(
        this.$http,
        `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
        params,
      )
    },
    validationForm() {
      this.isShow = true
      try {
        if (this.languageDefault.locale !== this.$i18n.locale) {
          const temp = JSON.parse(localStorage.getItem('siteID'))
          temp.language = this.languageDefault.locale
          localStorage.setItem('siteID', JSON.stringify(temp))
          this.updateSite({
            language: this.languageDefault.locale,
          })
          this.$i18n.locale = this.languageDefault.locale
          // window.location.reload()
          // this.isShow = true
          // return
        }
        let listUpdate = []
        listUpdate = this.languageOptions.filter(
          this.comparer(this.languageAddList, 'locale'),
        )
        if (listUpdate.length > 0) {
          if (listUpdate.find(val => val.is_active === false && val.locale === this.languageDefault.locale) === undefined) {
            listUpdate.map(async val => {
              const params = {
                site_id: JSON.parse(localStorage.getItem('siteID')).id,
                name: val.name,
                locale: val.locale,
                is_active: Number(val.is_active === true ? 1 : 0),
              }
              const res = await Request.put(
                this.$http,
                `${process.env.VUE_APP_API_URL}/language/${val.id}`,
                params,
              )
              if (res.status === 200) {
                this.isShow = false
                if (res.data.status) {
                  this.setLanguage()
                  window.location.reload()
                // this.$toast({
                //   component: ToastificationContent,
                //   position: 'top-right',
                //   props: {
                //     title: 'Congratulation !',
                //     icon: 'CheckCircleIcon',
                //     variant: 'success',
                //     text: 'Update language success',
                //   },
                // })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Opps! Something wrong',
                      icon: 'AlertOctagonIcon',
                      variant: 'danger',
                      text: String(this.showError(res.data.error, ',')),
                    },
                  })
                }
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: 'Cannot deactive default language',
              },
            })
            this.isShow = false
          }
        }
        if (this.languageAddList.length > 0) {
          this.languageAddList.map(async val => {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              name: val.name,
              locale: val.locale,
              is_active: Number(val.is_active === true ? 1 : 0),
              img: 'null',
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/language`,
              params,
            )
            if (res.status === 200) {
              this.isShow = false
              if (res.data.status) {
                this.languageAddList = []
                this.setLanguage()
                // this.$toast({
                //   component: ToastificationContent,
                //   position: 'top-right',
                //   props: {
                //     title: 'Congratulation !',
                //     icon: 'CheckCircleIcon',
                //     variant: 'success',
                //     text: 'New language was added',
                //   },
                // })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          })
        }
      } catch (error) {
        this.isShow = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
